import React from "react";
import style from './Settings.module.css';

const Settings = () => {
    return (
        <div className={style.container}>
            <div className={style.content}>
                <h3>Settings</h3>
            </div>
        </div>
    )
}

export default Settings;